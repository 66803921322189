import { Controller } from "stimulus"
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = [
    'fileField', 'fileName'
  ]
  static values = {
    id: Number
  }

  fileSelected() {
    const filename = this.fileFieldTarget.value.split("\\").pop();
    this.fileNameTarget.classList.add('selected')
    if(filename.split('.').pop() != 'csv'){
      this.fileFieldTarget.value = ''
      this.fileNameTarget.innerHTML = "Only CSV file allowed"
      return
    }
    this.fileNameTarget.innerHTML = filename
  }

  connect() {
    // Turbolinks refuses to connect to the channel again on cache,
    // This page needs the cache due to table data. Initializing again seems to work
    if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.setupChannel()
    }
    this.setupChannel()
  }

  initialize() {
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  setupChannel() {
    this.channel = this.application.consumer.subscriptions.create(
      {
        channel: 'OrganizationUserUploaderChannel',
        id: this.idValue
      },
      {
        received (data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      }
    );
  }
}
