import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "form", "table", "column", "input", "sort"
  ]

  connect() {
  }

  initialize() {
  }

  submit() {
    // Stop the form from submitting
    event.preventDefault()

    // Get the form data from the submitted filter form
    let formData = new FormData(event.target)

    // Remove the commit button text from the params
    formData.delete('commit')

    // parameterize the data with JS since it doesn't need to hit the server
    let params = new URLSearchParams(formData).toString()

    return false
  }

  updateTable(table, params) {
    let requestUrl = this.setParameterizedUrl(table.getAttribute('data-url'), params)
    fetch(requestUrl)
      .then(response => response.text())
      .then(function(html) {
        table.outerHTML = html
      })
  }

  updateChart(chart, params) {
    let requestUrl = this.setParameterizedUrl(chart.getAttribute('data-url'), params)
    Chartkick.charts[`${chart.getAttribute('data-id')}`].updateData(requestUrl)
  }

  setParameterizedUrl(url, params) {
    return `${url}?${params}`
  }

}
