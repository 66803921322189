import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['passwordField']

  connect() {
  }

  showPasswordField() {
    this.passwordFieldTarget.classList.remove('d-none')
  }
}
