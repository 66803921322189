import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    'pending',
    'archived',
    'amendConfirmation',
    'amendChangesForm',
    'reviewContract',
    'rejectContract',
    'requestChanges',
    'terminateContract',
    'showNewForm',
    'showRequestForm'
    ]

  static values = {
    usersCount: Number,
    maxUsers: Number,
    userId: String,
    userName: String,
    isReviewed: Boolean,
    isVoided: Boolean,
    isCreatingContract: Boolean
  }

  connect() {
    super.connect()
    if(this.isCreatingContractValue) {
      this.usersCountValue = 1
      this.maxUsersValue = 4
      this.setAllInputFieldLengths($('.lc-form__signatory_name'))
    } else {
      $('#other_request_changes').hide()
      $('#name_request_changes').hide()
      this.setAllInputFieldLengths($('.lc-form__signatory_name'))
      if(!this.isReviewedValue || this.isVoidedValue)
        this.createEvent("open_review_modal")
    }

  }

  showArchivedLC() {
    this.pendingTarget.classList.add('d-none')
    this.archivedTarget.classList.remove('d-none')
  }

  showPendingLC() {
    this.archivedTarget.classList.add('d-none')
    this.pendingTarget.classList.remove('d-none')
  }

  showRequestSignature() {
    if ($("#user_1").val() == '')
      alert('Please Enter Name')
    else {
    this.showNewFormTarget.classList.add('d-none')
    this.showRequestFormTarget.classList.remove('d-none')
    }
  }

  showNewLoveContract() {
    this.showNewFormTarget.classList.remove('d-none')
    this.showRequestFormTarget.classList.add('d-none')
  }

  openModal(e) {
    if(e.currentTarget.tagName == 'INPUT'){
      this.setNameFieldValue(e.currentTarget.value)
      this.setUserId(e.currentTarget.id)
      this.setModalButtonValue('Add to contract')
    }
    this.createEvent("modal_open")
    $('#user-name-field').trigger('focus')
  }

  closeModal() {
    this.setNameFieldError('')
    this.setNameFieldValue('')
    this.setModalButtonValue('Add to contract')
    this.setUserId('')
    this.createEvent("modal_close")
  }

  addUser() {
    this.userNameValue = $("#user-name-field").val().trim()
    if(this.userNameValue) {
      if(this.userIdValue)
        $('#' + this.userIdValue ).val(this.userNameValue)
      else {
        var new_user = `
        <span>
          <span> and </span>
          <input type="text" name="signatory_name[]" class="lc-form__signatory_name" id="user_` +
            (this.usersCountValue + 1) +
            `" autocomplete="off" data-action="click->love-contract#openModal" value="` +
            this.userNameValue + `">
        </span>`
        $("#love-contract-users").append(new_user)

        let emailField = `<div id='email_field_user_` + (this.usersCountValue + 1) + `'><p class='mt-5'><span id="display_user_` + (this.usersCountValue + 1) + `"></span></p>`;
        emailField += `<input type="email" placeholder="Enter email address" class="form-control" id="email_` + (this.usersCountValue + 1) + `" name="email_` + (this.usersCountValue + 1) + `" required/></div>`;
        $('#name_list_for_email').append(emailField);
        $('#display_user_' + (this.usersCountValue + 1)).html($('#user_' + (this.usersCountValue + 1)).val());

        this.usersCountValue++
        this.addUserSuccess()
      }
      if(this.userIdValue == '')
        this.setInputFieldLength($('#user_'+ this.usersCountValue))
      else {
        this.setInputFieldLength($('#' + this.userIdValue))
        if($('#display_' + this.userIdValue) != '')
          $('#display_' + this.userIdValue).html($('#' + this.userIdValue).val());
      }
      this.closeModal()
    }
    else {
      this.setNameFieldError("Please Enter the Name")
    }
  }

  removeUser(e) {
    e.currentTarget.parentElement.remove()
    $('#email_field_user_' + this.usersCountValue).remove()
    this.usersCountValue--
    this.removeUserSuccess()
  }

  addUserSuccess() {
    if (this.usersCountValue > 1 ) {
      this.addRemoveUserButton()

      if (this.usersCountValue > 2) {
        $('#user_'+ (this.usersCountValue - 1)).siblings().html(', ')
        $('#lc-remove-user').first().remove()

        if(this.usersCountValue == this.maxUsersValue) {
          $('.add-new-user').attr('disabled','disabled')
          $('.add-new-user').css({'color':'grey', 'cursor':'not-allowed'});
        }
      }
    }
  }

  removeUserSuccess() {
    if (this.usersCountValue > 1) {
      $('#user_'+ this.usersCountValue).siblings().html(' and ')
      this.addRemoveUserButton()
    }

    if(this.usersCountValue < this.maxUsersValue) {
      $('.add-new-user').removeAttr('disabled','disabled')
      $('.add-new-user').css({'color':'#8CA6E8', 'cursor':'pointer'});
    }
  }

  setNameFieldValue(value) {
    $("#user-name-field").val(value)
  }

  setNameFieldError(value) {
    $('#user-name-error').html(value)
  }

  setModalButtonValue(value) {
    $('button[name="modal_button"]').html(value)
  }

  setUserId(value) {
    this.userIdValue = value
  }

  addRemoveUserButton() {
    $('<svg id="lc-remove-user" data-action="click->love-contract#removeUser" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" class="bi bi-dash-circle-fill lc-form__remove-user" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/></svg>').insertAfter('#user_' + this.usersCountValue)
    
  }

  createEvent(event_name) {
    const event = new CustomEvent(event_name)
    window.dispatchEvent(event)
  }

  setAllInputFieldLengths(fields) {
    const that = this
    fields.each(function(){ that.setInputFieldLength($(this)) })
  }

  setInputFieldLength(field) {
    const fontSize = this.getTextWidth(field.val(), this.getCanvasFont(field));
    field.val().length < 15 ? field.width(fontSize) : field.width('120px')
  }

  /**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  *
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  *
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
  getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }

  getCanvasFont(el = document.body) {
    const fontWeight = this.getCssStyle(el[0], 'font-weight') || 'normal';
    const fontSize = this.getCssStyle(el[0], 'font-size') || '16px';
    const fontFamily = this.getCssStyle(el[0], 'font-family') || 'Times New Roman';

    return `${fontWeight} ${fontSize} ${fontFamily}`;
  }

  showAmmendForm() {
    this.amendConfirmationTarget.classList.add('d-none')
    this.terminateContractTarget.classList.add('d-none')
    this.amendChangesFormTarget.classList.remove('d-none')
    this.createEvent('open_confirmation_modal')
  }

  showTerminateModal() {
    this.amendConfirmationTarget.classList.add('d-none')
    this.amendChangesFormTarget.classList.add('d-none')
    this.terminateContractTarget.classList.remove('d-none')
    this.createEvent('open_confirmation_modal')
  }

  showAmmendConfirmation() {
    this.amendConfirmationTarget.classList.remove('d-none')
    this.amendChangesFormTarget.classList.add('d-none')
    this.terminateContractTarget.classList.add('d-none')
    this.createEvent('open_confirmation_modal')
  }

  showRejectContract() {
    this.reviewContractTarget.classList.add('d-none')
    this.requestChangesTarget.classList.add('d-none')
    this.rejectContractTarget.classList.remove('d-none')
    this.createEvent('open_review_modal')
  }

  showRequestChanges() {
    this.reviewContractTarget.classList.add('d-none')
    this.requestChangesTarget.classList.remove('d-none')
    this.rejectContractTarget.classList.add('d-none')
    this.createEvent('open_review_modal')
  }

  showNameRequestChanges() {
    $('#show_love_contract').hide()
    $('#other_request_changes').hide()
    $('#name_request_changes').show()
  }

  showOtherRequestChanges() {
    $('#show_love_contract').hide()
    $('#other_request_changes').show()
    $('#name_request_changes').hide()
  }

  showLoveContract() {
    $('#show_love_contract').show()
    $('#other_request_changes').hide()
    $('#name_request_changes').hide()
  }
}
