import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "tab", "content"
  ]

  static values = {
    activeTabClass: String
  }

  connect() {
  }

  toggle() {
    this.activate(event.target)
  }

  activate(self) {
    this.deactivate()
    let index = this.tabTargets.indexOf(self);

    self.classList.add(this.activeTabClassValue)
    this.contentTargets[index].classList.remove('d-none')
  }

  deactivate() {
    this.contentTargets.forEach(target => target.classList.add('d-none'))
    this.tabTargets.forEach(target => target.classList.remove(this.activeTabClassValue));
  }
}
